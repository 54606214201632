<template>

  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="9"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title float-left pr-1 mb-0">
              Role Detail
            </h1>
          </b-col>
        </b-row>
      </b-col>

      <!-- Content Right -->
      <b-col
        class="content-header-right text-md-right d-md-block d-none mb-1"
        md="3"
        cols="12"
      >
        <b-button
          variant="primary"
          :to="{ name: 'directory-roles-edit', params: { id: $route.params.id } }"
        >
          <feather-icon
            icon="EditIcon"
            class="mr-50"
            size="16"
          />
          <span class="align-middle">Edit Role</span>
        </b-button>
      </b-col>
    </b-row>
    <!-- Table Container Card -->

    <b-card
      no-body
      class="mb-3 p-2"
    >
      <b-table-simple
        borderless
        responsive
      >
        <b-tbody>
          <b-tr>
            <b-th>
              Role
            </b-th>
            <b-td>{{ role.role }}</b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Module
            </b-th>
            <b-td>{{ role.modules }}</b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Redirect URL
            </b-th>
            <b-td>{{ role.redirectURL }}</b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Description
            </b-th>
            <b-td>{{ role.description }}</b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Permissions
            </b-th>
            <b-td>
              <b-button
                v-for="(permission, key) in role.permissions"
                :key="key"
                :title="permission.name"
                variant="outline-dark"
                class="small-badge-button"
              >
                {{ permission.name }}
              </b-button>
            </b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Created On
            </b-th>
            <b-td>{{ dateFormatWithTime(role.createdAt) }}</b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Last Updated At
            </b-th>
            <b-td>{{ dateFormatWithTime(role.updatedAt) }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-card>

    <user-list
      :role-id="$route.params.id"
    />
  </div>
</template>

<script>
import {
  BCard, BRow, BButton, BCol, BTableSimple, BTbody, BTr, BTh, BTd,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import UserList from '../users/UserList.vue'

export default {
  components: {
    BCard,
    BRow,
    BButton,
    BCol,
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
    UserList,
  },
  data() {
    return {
      role: {},
    }
  },
  created() {
    this.$http.get(`directory/roles/${this.$route.params.id}/show`)
      .then(response => {
        this.role = response.data ?? {}
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
}
</script>
